
<template>
<div>
  <swiper class="swiper" :options="swiperOption2">
  <swiper-slide>
  <swiper class="swiper" :options="swiperOption">
    <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
    <swiper-slide class="custom-slide">
      <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_1.png'">
      <!-- <img alt="" class="bg-img" src="../../assets/index/banner_1.png"> -->
      <!-- <div class="title" data-swiper-parallax="-100">Slide 1</div>
      <div class="subtitle" data-swiper-parallax="-240">Subtitle</div>
      <div class="text" data-swiper-parallax="-360">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. Suspendisse in sem justo. Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod. Aliquam hendrerit lorem at elit facilisis rutrum. Ut at ullamcorper velit. Nulla ligula nisi, imperdiet ut lacinia nec, tincidunt ut libero. Aenean feugiat non eros quis feugiat.</p> -->
      <!-- </div> -->
    </swiper-slide>
    <swiper-slide class="custom-slide">
      <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_2.png'">
        <!-- <img alt="" class="bg-img" src="../../assets/index/banner_2.png"> -->
      <!-- <div class="title" data-swiper-parallax="-100">Slide 2</div> -->
    </swiper-slide>
    <swiper-slide class="custom-slide">
      <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_3.png'">
        <!-- <img alt="" class="bg-img" src="../../assets/index/banner_3.png"> -->
      <!-- <div class="title" data-swiper-parallax="-100">Slide 3</div> -->
    </swiper-slide>
    <swiper-slide class="custom-slide">
      <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_4.png'">
        <!-- <img alt="" class="bg-img" src="../../assets/index/banner_3.png"> -->
      <!-- <div class="title" data-swiper-parallax="-100">Slide 3</div> -->
    </swiper-slide>
    <swiper-slide class="custom-slide">
      <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_5.png'">
        <!-- <img alt="" class="bg-img" src="../../assets/index/banner_3.png"> -->
      <!-- <div class="title" data-swiper-parallax="-100">Slide 3</div> -->
    </swiper-slide>
    <!-- <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div> -->
    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
  </swiper>
    <div class="custom_display_page">
      <span class="span1">01</span> <span class="h_line"> </span><span class="span2">03</span>   滚动更多 <span class="span3">  /  </span> Scroll More<img class="mouse_icon" src="../../assets/groupIntro/mouse_icon.gif"/>
      <div class="down_icon"><img src="../../assets/index/down_allow_white.png"/></div>
    </div>
  </swiper-slide>
  <swiper-slide>
  <div style="height:100vh" class="index_page_two">
    <div class="index_flex">
      <div class="flex_item">
         <img alt="" id="img_box" src="../../assets/index/index_person.png">
      </div>
      <div class="flex_item flex_item_2" id="p_t_rt">
        <h1>QET</h1>
        <h1>FOUNDATION</h1>
        <p>琪尔特基石</p>
        <div class="custom-tab">
            <ul class="custom-tab-bar">
                <li @mouseover="selectTab(1)" v-bind:class="{currentTab: currentTabValue==1}">中国设计</li>
                <li @mouseover="selectTab(2)" v-bind:class="{currentTab: currentTabValue==2}">匠心精工</li>
                <li @mouseover="selectTab(3)" v-bind:class="{currentTab: currentTabValue==3}">科技实力</li>
            </ul>
            <div class="custom-tab-content" style="position: relative;">
                <!-- 中国设计 -->
                <div class="custom_tab_content_text" v-if="currentTabValue==1">
                        以设计为先导<br>
                        琪尔特19年设计师文化沉淀<br>
                        希望展示给世界的是"中国设计"
                </div>
                <!-- 匠心精工 -->
                <div class="custom_tab_content_text" v-if="currentTabValue==2">
                        <div style="font-size:16px;font-weight:bolder">琪者，美玉也，匠心雕琢</div>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">设计师出身的创始人林玉琪先生赋予琪尔特独特的</p>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">设计师文化和企业气质，琪尔特近20年的鞋服行业沉淀，</p>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">恰如经时间打磨与历练的美玉，匠心雕琢成器。</p>
                        <div style="font-size:16px;font-weight:bolder">真诚合作，俱进为赢</div>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">琪尔特真诚携手每一位事业伙伴，</p>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">不断优化合作环境，赋予事业伙伴发展能量、</p>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">价值提升和财富增长，共同进步，共赢未来。</p>
                        <div style="font-size:16px;font-weight:bolder">以新为特，同步时间</div>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">顺应潮流发展和产业变革浪潮的趋势，</p>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">造就了充满时代感的琪尔特：</p>
                        <p style="font-size:14px;margin:0;padding:0;margin-top: -18px;">稳健、精准，勇于创新，与时代同步。</p>
                </div>
                <!-- 科技实力 -->
                <div class="custom_tab_content_text" v-if="currentTabValue==3">
                        以创新科技，<br>
                        驱动鞋服产业新未来。<br>
                        <p style="font-size:12.5px;margin:10px 0 0 0;padding:0">Drive the new future of the footwear industry</p>
                        <p style="font-size:12.5px;margin:0;padding:0;margin-top: -16px;">with innovative technology</p>
                </div>
                <!-- <div class="custom-a-box" id="p_a_box" @click="handleToGroup">→</div> -->
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="up_icon"><img src="../../assets/index/up_allow.png"/></div>
  <div class="custom_display_page custom_display_page_2">
    <span class="span1">02</span> <span class="h_line"> </span><span class="span2">03</span>   滚动更多 <span class="span3">  /  </span> Scroll More<img class="mouse_icon" src="../../assets/groupIntro/mouse_icon.gif"/>
    <div class="down_icon"><img src="../../assets/index/down_allow.png"/></div>
  </div>
  </swiper-slide>
  <swiper-slide>
    <div style="height:100vh" class="index_page_3">
      <div class="index_flex">
        <div class="flex_item flex_item_2 item_1" id="p_t_rt2">
          <h1>LATEST</h1>
          <h1>NEWS</h1>
          <p>最新动态</p>
          <div class="text_box" @click="handleToDetail(lastestNews)">
            <h3>公司动态  {{lastestNews.updated_at}}</h3>
            <h2>{{lastestNews.title}}</h2>
            <div class="text_content">
              {{lastestNews.description}}
            </div>
          </div>
          <!-- <div class="custom-a-box custom-a-box_2" @click="handleToDetail(lastestNews)">→</div> -->
        </div>
        <div class="flex_item item_2">
          <!-- <img alt="" src="../../assets/index/index_3_image.png"> -->
          <img alt="" :src="lastestNews.small_image_url">
        </div>
      </div>
    </div>
    <div class="up_icon"><img src="../../assets/index/up_allow.png"/></div>
  </swiper-slide>
  </swiper>
  <!-- 第一次进入展示 -->
  <loadingPage ref="loadingPage"></loadingPage>
</div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import loadingPage from "../loading";
  export default {
    name: 'swiper-example-parallax',
    title: 'Parallax',
    components: {
      Swiper,
      SwiperSlide,
      loadingPage,
    },
    data() {
      return {
        swiperOption: {
          speed: 600,
          loop : true,
          // autoplay:true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          parallax: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
        swiperOption2: {
          direction: 'vertical',
          slidesPerView: 1,
          height: window.innerHeight,
          spaceBetween: 30,
          mousewheel: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          on: {
            slideChangeTransitionStart: function(){
              if (this.activeIndex === 1) {
                let p_t_rt = document.getElementById('p_t_rt');
                p_t_rt.classList.add('animated')
                p_t_rt.classList.add('fadeInUp')
              } else{
                let p_t_rt = document.getElementById('p_t_rt');
                p_t_rt.classList.remove('animated')
                p_t_rt.classList.remove('fadeInUp')
              }
              if (this.activeIndex === 2) {
                let p_t_rt2 = document.getElementById('p_t_rt2');
                p_t_rt2.classList.add('animated')
                p_t_rt2.classList.add('fadeInUp')
              } else{
                let p_t_rt2 = document.getElementById('p_t_rt2');
                p_t_rt2.classList.remove('animated')
                p_t_rt2.classList.remove('fadeInUp')
              }
            }
          }
        },
        currentTabValue: 1,//当前选择的tab值
        lastestNews: [],
      }
    },
    created() {
      
    },
    mounted() {
      let isFirstVisit = JSON.parse(localStorage.getItem('isFirstVisit'));
      if (isFirstVisit===null) {
        localStorage.setItem('isFirstVisit', false);
        setTimeout(function(){
          this.$refs.loadingPage.isShow = false;
        }.bind(this),2500)
      } else{
        this.$refs.loadingPage.isShow = false;
      }
      this.getCompanyLastestNews();
    },
    watch:{
    },
    methods:{
      //选择
      selectTab (type) {
          switch(type) {
              case 1:
              this.currentTabValue = 1;
              break;
              case 2:
              this.currentTabValue = 2;
              break;
              case 3:
              this.currentTabValue = 3;
              break;
              default:
              break;
          }
      },
       // 获取新闻
        getCompanyLastestNews() {
            let cond= {
                params:{
                    top_category_id: 1,
                    flags: 'recommend',
                    page: 1,
                    per_page: 1
                }
            };
            this.$http.get(this.$apiUrl.common.getNews,cond).then((res) => {
                this.lastestNews = res.paginate.data && res.paginate.data[0];
            }).catch(()=>{
                
            });
        },
        handleToDetail(item) {
            this.$router.push({name: 'detail', query:{id: item.id}})
        },
        handleToGroup() {
            this.$router.push({name: 'groupIntro'})
        },
    },
    //创建前设置 body样式 隐藏滚动条
    beforeCreate () {
          document.querySelector('body').setAttribute('style', 'overflow:hidden;')
    },
    //销毁前清除
    beforeDestroy () {
          document.querySelector('body').removeAttribute('style')
    },
    beforeRouteEnter(to, from, next) {
      // 在渲染该组件的对应路由被 confirm 前调用
      // 不！能！获取组件实例 `this`
      // 因为当守卫执行前，组件实例还没被创建
      next(vm => {
        // 通过 `vm` 访问组件实例
        console.log(vm);
      })
    },
  }
</script>

<style scoped>
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    left: 294px;
}
/* @-webkit-keyframes cusScale{
  100%{transform: scale(1.2);}
} */
  .bg-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* animation: cusScale 3.56s infinite; */
  }
  .custom-slide{
    height: 100vh;
    overflow: hidden;
  }
  .index_page_two{
    margin-top: 100px;
    margin-left: 80px;
    margin-right: 80px;
  }
  .index_flex{
    display: flex;
  }
  .flex_item{
    flex: 1
  }
  .flex_item:first-child{
    flex: 2
  }

  .flex_item img{
    width: 98%;
  }
  .flex_item h1{
    padding: 0;
    margin: 0;
    font-size: 46px;
    font-family: AlibabaPuHuiTiM;
    font-weight: bolder;
    color: #040000;
    text-align: right;
  }
  .flex_item p{
    padding: 0;
    margin: 0;
    font-size: 33px;
font-family: AlibabaPuHuiTiM;
font-weight: 400;
color: #231815;
text-align: right;
    margin-bottom: 80px;
  }
  .custom-tab{
    
  }
  .custom-tab ul{
    list-style: none;
    display: flex;
        justify-content: flex-end;
  }
  .custom-tab ul li{
font-size: 23px;
font-family: FZLTHJW;
font-weight: normal;
color: #231815;
    padding: 0 26px;
    cursor: pointer;
  }
  .custom-tab ul li:last-child{
    padding: 0 0 0 26px;
  }
  .custom_tab_content_text{
font-size: 24px;
font-family: FZLTHJW;
font-weight: normal;
color: #231815;
line-height: 50px;
    margin-top: 100px;
    text-align: right;
  }
  .custom-a-box{
    width: 60px;
    height: 60px;
    background: #EE660E;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
    font-size: 25px;
        position: absolute;
    right: 0;
    top: 210px;
  }
  .currentTab{
    color: #EE660E !important;
}
.text_box{
  width: 88%;
  padding-top: 45px;
  cursor: pointer;
}
.text_box h2{
font-size: 25px;
font-family: FZLTHJW;
font-weight: normal;
color: #231815;
line-height: 36px;
    padding: 2px 0 26px 0;
    margin: 0;
}
.text_box h2:hover{
  color: #FFA56A;
}
.text_box h3{
font-size: 20px;
font-family: FZLTHJW;
font-weight: normal;
color: #9C9C9C;
line-height: 31px;
}
.text_content{
font-size: 18px;
font-family: FZLTHJW;
font-weight: normal;
color: #5F5F5F;
line-height: 31px;
}
.custom-a-box_2{
left: 0;
    bottom: -90px;
    top: initial;
}
.item_1{
  flex: 2;
  text-align: left;
      position: relative;
}
.item_1 h1, .item_1 p{
  text-align: left;
}
.item_2{
  flex: 3;
      margin-top: 105px;
}
.index_page_3 {
    margin-top: 60px;
    margin-left: 300px;
    margin-right: 80px;
}
.custom_display_page{
  font-weight: bolder;
    font-size: 18px;
    color: #ffffff;
    position: absolute;
    bottom: 22px;
    left: 44%;
    z-index: 9;
}
.custom_display_page_2{
  color: #000000;
}
.custom_display_page .mouse_icon{
    vertical-align: middle;
    padding-left: 12px;
}
.h_line{
width: 70px;
    border: 1px solid #FFFFFF;
    display: inline-block;
    position: relative;
    top: -6px;
    margin: 0 5px;
}
.custom_display_page_2 .h_line{
    border: 1px solid #000000;
}
.custom_display_page .span1{

}
.custom_display_page .span2{
opacity: 0.3;
padding-right: 5px;
}
.custom_display_page .span3{
color: #EE660E;
padding: 0 5px;
}
.down_icon{
  text-align: center;
    position: relative;
    top: 12px;
}
.up_icon{
    position: absolute;
    top: 32px;
    left: 57%;
    z-index: 9;
}
@media screen and (max-width:1500px){
  .index_page_two {
    margin-left: 128px;
}
.flex_item h1{
    font-size: 26px;
}
.flex_item p{
    font-size: 20px;
}
.custom-tab ul li {
    font-size: 16px;
}
.custom_tab_content_text{
    font-size: 18px;
    line-height: 36px;
}
.custom-a-box{
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 21px;
    top: 150px;
}
.custom-a-box_2 {
    left: 0;
    bottom: -100px;
    top: initial;
}
.text_content {
    font-size: 14px;
    line-height: 26px;
}
.text_box h3{
    font-size: 14px;
}
.text_box h2 {
    font-size: 16px;
    line-height: 26px;
    padding: 0px 0 16px 0;
    margin: 0;
}
.custom_display_page{
  font-weight: bolder;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    bottom: 12px;
    left: 48%;
    z-index: 9;
}
.custom_display_page_2{
  color: #000000;
}
.custom_display_page .mouse_icon{
    vertical-align: middle;
        padding-left: 10px;
    width: 14px;
}
.h_line{
width: 70px;
    border: 1px solid #FFFFFF;
    display: inline-block;
    position: relative;
    top: -4px;
    margin: 0 5px;
}
.custom_display_page_2 .h_line{
    border: 1px solid #000000;
}
.custom_display_page .span1{

}
.custom_display_page .span2{
opacity: 0.3;
padding-right: 5px;
}
.custom_display_page .span3{
color: #EE660E;
padding: 0 5px;
}
.down_icon{
  text-align: center;
    position: relative;
    top: 6px;
}
.down_icon img{
      width: 30px;
}
.up_icon{
    position: absolute;
    top: 32px;
    left: 58%;
    z-index: 9;
}
.up_icon img{
  width: 30px;
}
}
.custom-a-box:hover{
  font-weight: bolder;
  cursor: pointer;
  color: #f39556;
}
</style>