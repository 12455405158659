<template>
    <div class="load-content" v-show="isShow">
        <div class="load_title">匠心智造   始于2001</div>
        <div class="load_bar">
            <div class="load_bar_outer">
                <div class="load_bar_inner" id="load_bar_inner"></div>
            </div>
            <div class="load_text" id="load_text">0%</div>
        </div>
        <div class="load_foot">
            <p><span style="font-weight: bolder;">COPYRIGHT ⓒ琪尔特集团有限公司</span> All rights reserved</p>
            <p>闽ICP备19010982号-1  闽公网安备35059102000017</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: true
        }
    },
    created() {
        
    },
    mounted() {
        this.setProgress();
    },
    methods:{
        setProgress() {
            let percentage = 0;
            let timer = setInterval(function(){
                percentage = percentage + 12
                if (percentage>=100) {
                    clearInterval(timer)
                    percentage = 100;
                }
                document.getElementById('load_bar_inner').style.width = percentage + '%';
                document.getElementById('load_text').innerHTML = percentage + '%';
            },200)
        }
    }
}
</script>
<style scoped>
.load-content{
       z-index: 99999;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
}
.load_title{
font-size: 83px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #EE660E;
text-align: center;
    margin-top: 20%;
}
.load_bar{
    display: inline-block;
    vertical-align: middle;
    width: 90%;
    margin-left: 5%;
    box-sizing: border-box;
    margin-top: 10px;
    position: relative;
}
.load_bar_outer{
    height: 4px;
    border-radius: 100px;
    background-color: #ebeef5;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
}
.load_bar_inner{
    width: 0%;
position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #EE660E;
    text-align: right;
    border-radius: 100px;
    line-height: 1;
    white-space: nowrap;
    transition: width .6s ease;
}
.load_text{
        position: absolute;
    right: -48px;
    top: -10px;
    color: #EE660E;
}
.load_foot{
    font-size: 13px;
font-family: AlibabaPuHuiTiB;
font-weight: 400;
color: #EE660E;
line-height: 21px;
position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;
}
.load_foot p{
padding: 0;
margin: 0;
}
</style>